import {
  LogLevel as OptimizelyLogLevel,
  createInstance,
  enums,
  type Client,
} from '@optimizely/optimizely-sdk';

import logger from 'shared/services/logger/logger';

import {
  dispatchOptimizelyInitEvent,
  dispatchOptimizelyInitFailureEvent,
} from '../tracker/events';

import { handleDecisionEvent } from './decision-event';

let OptimizelySingleton: null | Client = null;

const createClient = (): Client => {
  void dispatchOptimizelyInitEvent();

  const client = createInstance({
    sdkKey: process.env.NEXT_PUBLIC_OPTIMIZELY_KEY,
    errorHandler: {
      handleError: (error): void => {
        logger.error(error);
        void dispatchOptimizelyInitFailureEvent();
      },
    },
    logLevel:
      process.env.NODE_ENV === 'development'
        ? OptimizelyLogLevel.WARNING
        : OptimizelyLogLevel.ERROR,
  });

  if (!client) {
    throw new Error('Optimizely browser client failed to initialize');
  }

  client.notificationCenter.addNotificationListener(
    enums.NOTIFICATION_TYPES.DECISION,
    handleDecisionEvent,
  );

  return client;
};

export const getOptimizelyClientForBrowser = (): Client => {
  if (OptimizelySingleton) {
    return OptimizelySingleton;
  }

  OptimizelySingleton = createClient();

  return OptimizelySingleton;
};
