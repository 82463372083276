import type { Maybe } from 'types/util';
import { getLast } from 'utils/querystring';

export const getReferrerParam = (
  referrer?: null | string | (string | null)[],
): Maybe<string> => {
  if (!referrer) {
    return null;
  }

  if (typeof referrer === 'string') {
    return referrer;
  }

  return getLast(referrer.filter((value) => typeof value === 'string'));
};
