/* eslint-disable @typescript-eslint/naming-convention */
import queryString from 'query-string';

import type { AttributionParams } from 'checkout/types/checkout';
import { getCookie } from 'shared/infra/cookies';
import isServer from 'shared/utils/is-server';

export interface TrackingParams {
  ip_address?: string;
  user_agent?: string;
  gclid?: string | string[];
  gbraid?: string | string[];
  wbraid?: string | string[];
  fbclid?: string | string[];
  fb_event_name?: string;
  fbp?: string;
  irclickid?: string | string[];
  program?: string | string[];
}

const FACEBOOK_BROWSER_ID_PARAM = '_fbp';
const DEFAULT_FB_EVENT_NAME = 'Purchase';

export const ATTRIBUTION_PARAMS = {
  REFERRER: 'referrer',
  PARTNER: 'partner',
  TAGS: 'tags',
  SHARE_ID: 'share_id',
  SHARE_CAMPAIGN: 'share_campaign',
  SHARE_SOURCE: 'share_source',
  FBUY_REF_CODE: 'fbuy_ref_code',
} as const;

export const sanitizeParameters = <T extends Record<string, unknown>>(
  params: T,
): Partial<T> =>
  Object.keys(params).reduce((acc, key) => {
    if (params[key]) {
      return { ...acc, [key]: params[key] };
    }

    return acc;
  }, {} as Partial<T>);

export const getAttributionParams = (
  search: string = !isServer ? window?.location?.search : '',
): AttributionParams => {
  const {
    [ATTRIBUTION_PARAMS.REFERRER]: referrer,
    [ATTRIBUTION_PARAMS.PARTNER]: partner_code,
    [ATTRIBUTION_PARAMS.TAGS]: tags,
    [ATTRIBUTION_PARAMS.SHARE_ID]: share_id,
    [ATTRIBUTION_PARAMS.SHARE_CAMPAIGN]: share_campaign,
    [ATTRIBUTION_PARAMS.SHARE_SOURCE]: share_source,
    [ATTRIBUTION_PARAMS.FBUY_REF_CODE]: fbuy_ref_code,
  } = queryString.parse(search);

  return {
    referral_code: fbuy_ref_code || referrer,
    partner_code,
    tags,
    share_id,
    share_campaign,
    share_source,
  };
};

export const getTrackingParams = (
  userIp?: string,
  windowObject: Window = !isServer ? window : null,
): TrackingParams => {
  const userAgent = windowObject?.navigator?.userAgent;

  /**
   * These parameters are used to track google and facebook campaigns.
   * gbraid and wbraid are mostly used as replacements for gclid for tracking
   * on iOS devices.
   */
  const { gclid, gbraid, wbraid, fbclid, irclickid, program } =
    queryString.parse(windowObject?.location?.search);

  const fbp = getCookie(FACEBOOK_BROWSER_ID_PARAM) || '';

  const trackingParams = {
    ip_address: userIp,
    user_agent: userAgent,
    gclid,
    gbraid,
    wbraid,
    fbclid,
    irclickid,
    fb_event_name: DEFAULT_FB_EVENT_NAME,
    fb_fbp: fbp,
    program,
  };

  return sanitizeParameters(trackingParams);
};
