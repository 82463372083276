/* eslint-disable class-methods-use-this */
/* eslint-disable max-classes-per-file */
import fs from 'fs';
import { tmpdir } from 'os';
import { join } from 'path';

import { sleepMs } from './sleep';
import { Token } from './token';

export const TMP_DIR_NAME = 'cl-storage';
export const ACCESS_TOKEN_KEY = 'CL_ACCESS_TOKEN';

export type TokenStore = {
  get(marketCode: string): Promise<Token | void>;
  set(token: Token): Promise<void>;
  isInflight(marketCode: string): Promise<boolean>;
  setInflight(marketCode: string): Promise<void>;
  clearInflight(marketCode: string): void;
};

export class SessionTokenStore implements TokenStore {
  get(): Promise<Token | void> {
    const storedToken = sessionStorage.getItem(ACCESS_TOKEN_KEY);

    if (storedToken) {
      return Promise.resolve(Token.fromJson(storedToken));
    }

    return undefined;
  }

  set(token: Token): Promise<void> {
    sessionStorage.setItem(ACCESS_TOKEN_KEY, token.toJson());

    return Promise.resolve();
  }

  isInflight(): Promise<boolean> {
    return Promise.resolve(false);
  }

  setInflight(): Promise<void> {
    return Promise.resolve();
  }

  clearInflight(): void {}
}

// The cl auth endpoint throttles during static build time (too many requests).
// We can avoid this by caching the tokens on the file system.
// Why file system? Because each page built triggers a seperate build,
// and each build has its own memory.
export class FileTokenStore implements TokenStore {
  private tempDir = join(tmpdir(), TMP_DIR_NAME);

  constructor() {
    // read all files in tempDir and if they are inflight, remove them
    // this constructor is running multiple times (in multiple processes during build) but it should be fine
    if (fs.existsSync(this.tempDir)) {
      const files = fs.readdirSync(this.tempDir);
      files.forEach((file) => {
        const filePath = join(this.tempDir, file);

        if (file.endsWith('_inflight.json')) {
          try {
            fs.rmSync(filePath, { force: true });
          } catch (error) {
            console.error(error);
          }
        }
      });
    }
  }

  get(marketCode: string): Promise<Token | void> {
    const cacheFilePath = join(this.tempDir, `${marketCode}_token.json`);

    if (!fs.existsSync(cacheFilePath)) {
      return Promise.resolve();
    }

    const data = fs.readFileSync(cacheFilePath, { encoding: 'utf8' });
    const token = Token.fromJson(data);

    return Promise.resolve(token);
  }

  async set(token: Token): Promise<void> {
    const cacheFilePath = join(this.tempDir, `${token.marketCode}_token.json`);

    if (!fs.existsSync(this.tempDir)) {
      fs.mkdirSync(this.tempDir);
    }

    fs.writeFileSync(cacheFilePath, token.toJson());

    // Avoid throttling
    await sleepMs(1000);

    return Promise.resolve();
  }

  clear(marketCode: string): void {
    const cacheFilePath = join(this.tempDir, `${marketCode}_token.json`);

    if (!fs.existsSync(cacheFilePath)) {
      return;
    }

    fs.unlinkSync(cacheFilePath);
  }

  isInflight(marketCode: string): Promise<boolean> {
    const cacheFilePath = join(this.tempDir, `${marketCode}_inflight.json`);

    return Promise.resolve(fs.existsSync(cacheFilePath));
  }

  setInflight(marketCode: string): Promise<void> {
    const cacheFilePath = join(this.tempDir, `${marketCode}_inflight.json`);

    if (!fs.existsSync(this.tempDir)) {
      fs.mkdirSync(this.tempDir);
    }

    fs.writeFileSync(cacheFilePath, 'inflight');

    return Promise.resolve();
  }

  clearInflight(marketCode: string): void {
    const cacheFilePath = join(this.tempDir, `${marketCode}_inflight.json`);

    if (!fs.existsSync(cacheFilePath)) {
      return;
    }

    try {
      fs.rmSync(cacheFilePath, { force: true });
    } catch (error) {
      console.error(error);
    }
  }
}

export const createSessionTokenStore = (): SessionTokenStore =>
  new SessionTokenStore();

export const createFileTokenStore = (): FileTokenStore => new FileTokenStore();
