import { authenticate } from '@commercelayer/js-auth';

import isServer from 'shared/utils/is-server';

import { createTokenSource, type Config } from './auth';
import { createClient, type Client } from './client';
import {
  createFileTokenStore,
  createSessionTokenStore,
  type FileTokenStore,
  type SessionTokenStore,
} from './store';

const createStore = (): FileTokenStore | SessionTokenStore => {
  if (isServer) {
    return createFileTokenStore();
  }
  return createSessionTokenStore();
};

const tokenConfig: Config = {
  refreshBackoffMs: 200,
  refreshRetryAttempts: 5,
};

const getNewClient = (): Client => {
  const store = createStore();

  const tokenSource = createTokenSource(store, authenticate, tokenConfig);

  return createClient(tokenSource);
};

export const client = getNewClient();
