import type { authenticate } from '@commercelayer/js-auth';

import { sleepMs } from './sleep';
import type { TokenStore } from './store';
import { Token } from './token';

type GetTokenReq = {
  marketCode: string;
  clientId: string;
};

type AuthFn = typeof authenticate;

export type Config = {
  refreshBackoffMs: number;
  refreshRetryAttempts: number;
};

export class TokenSource {
  private store: TokenStore;

  private authFn: AuthFn;

  private config: Config;

  constructor(store: TokenStore, authFn: AuthFn, config: Config) {
    this.store = store;
    this.authFn = authFn;
    this.config = config;
  }

  async getToken(req: GetTokenReq): Promise<Token> {
    return this.requestToken(req);
  }

  private async requestToken(req: GetTokenReq, attempt = 1): Promise<Token> {
    if (attempt > this.config.refreshRetryAttempts) {
      throw new Error(
        `Failed to get token, max number of ${this.config.refreshRetryAttempts} attempts reached. abort!`,
      );
    }

    const tokenFromStore = await this.store.get(req.marketCode);
    const isInflight = await this.store.isInflight(req.marketCode);

    if (tokenFromStore) {
      if (tokenFromStore.isExpired()) {
        if (process.env.CI) {
          // eslint-disable-next-line no-console
          console.debug(
            'Token is expired, refreshing',
            { token: tokenFromStore },
            Date.now(),
          );
        }

        return this.refreshExistingToken(tokenFromStore);
      }

      if (
        tokenFromStore.clientId !== req.clientId ||
        tokenFromStore.marketCode !== req.marketCode
      ) {
        if (isInflight) {
          await sleepMs(100);
          return this.requestToken(req, attempt); // do not increment attempt
        }

        return this.requestNewToken(req.marketCode, req.clientId);
      }

      return tokenFromStore;
    }

    if (isInflight) {
      await sleepMs(100);
      return this.requestToken(req, attempt); // do not increment attempt
    }

    return this.requestNewToken(req.marketCode, req.clientId);
  }

  private async requestNewToken(
    marketCode: string,
    clientId: string,
    attempt = 1,
  ): Promise<Token> {
    await this.store.setInflight(marketCode);
    const auth = await this.authFn('client_credentials', {
      clientId,
      scope: `market:code:${marketCode}`,
    });
    if (auth.errors) {
      const errors = Array.from(auth.errors) ?? [];
      if (
        errors.some((e) => e.code === 'THROTTLED') ||
        // @ts-ignore weird CL bug? where somethimes `errors` is not an array
        auth.errors.code === 'THROTTLED'
      ) {
        const timeout = (500 + Math.random() * 100) * 1.2 ** attempt;
        await sleepMs(timeout);
        return this.requestNewToken(marketCode, clientId, attempt + 1);
      }
      throw new Error(`Failed to authenticate market ${marketCode}`, {
        cause: auth.errors,
      });
    }

    const token = Token.fromAuthResponse(auth, marketCode, clientId);

    this.store.clearInflight(marketCode);
    await this.store.set(token);

    return token;
  }

  private async refreshExistingToken(
    existToken: Token,
    attempt = 1,
    backoffMs = this.config.refreshBackoffMs,
  ): Promise<Token> {
    if (attempt > this.config.refreshRetryAttempts) {
      throw new Error(
        `Failed to refresh token, max number of ${this.config.refreshRetryAttempts} attempts reached. abort!`,
      );
    }

    const token = await this.requestNewToken(
      existToken.marketCode,
      existToken.clientId,
    );

    // We will try to obtain a token different from
    // expiredToken by retrying if the returned token is still the same.
    // There seems to be an unfixed bug in the CL API that sometimes
    // returns the same token even if it is expired.
    if (token.accessToken === existToken.accessToken) {
      await sleepMs(backoffMs);

      return this.refreshExistingToken(
        existToken,
        attempt + 1,
        backoffMs + this.config.refreshBackoffMs,
      );
    }

    return token;
  }
}

export const createTokenSource = (
  store: TokenStore,
  authFn: AuthFn,
  config: Config,
): TokenSource => new TokenSource(store, authFn, config);
