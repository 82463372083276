/*
 * Any errors thrown by this function are only build time errors
 * As the values for these locales are only used in our `next.config.js` file
 */
/**
 * @function
 * @template {`${string}-${string}`} T
 * @param {Readonly<T[]>} locales
 * @returns {Readonly<T[]>}
 */
const returnValidLocales = (locales) => {
  // validating default en language for all locales
  // en locales to find
  const toFindEnLocales = new Set();
  // en locales which are found
  const alreadyFoundEnLocales = new Set();

  // cycle through locales to find where we need en default language
  locales.forEach((locale) => {
    const [lang, country] = locale.split('-');

    // verify that the '<language>-<country>' combination is provided for the locale
    if (!lang || !country) {
      throw new Error(
        `Only map <language>-<country> is accepted, please update locale: ${locale}`,
      );
    }

    if (lang !== 'en') {
      const enLocaleToFind = `en-${country}`;
      toFindEnLocales.add(enLocaleToFind);
    } else {
      alreadyFoundEnLocales.add(locale);
    }
  });

  // verify that all non en countries have an en-COUNTRY locale
  [...toFindEnLocales.keys()].forEach((entryToFind) => {
    if (!alreadyFoundEnLocales.has(entryToFind)) {
      const [, defaultLangNotFoundCountry] = entryToFind.split('-');
      throw new Error(
        `Missing default en language for country ${defaultLangNotFoundCountry} (en-${defaultLangNotFoundCountry})`,
      );
    }
  });

  return locales;
};

const DEFAULT_LOCALE = 'en-US';

// This collection should reflect type
// LOCALE_CODE from shared/infra/contentful/contentful.ts
// DEPENDENCY: KEEP NATIVE LANGUAGE FIRST IN THE COLLECTION - ['hr-HR', 'en-HR'] not ['en-HR', 'hr-HR']
const locales = /** @type {const} */ ([
  // Australia
  'en-AU',
  // Austria
  'de-AT',
  'en-AT',
  // Bulgaria
  'bg-BG',
  'en-BG',
  // Cyprus
  'el-CY',
  'en-CY',
  // Croatia
  'hr-HR',
  'en-HR',
  // Czech Republic
  'cs-CZ',
  'en-CZ',
  // Denmark
  'da-DK',
  'en-DK',
  // Estonia
  'et-EE',
  'en-EE',
  // Finland
  'fi-FI',
  'en-FI',
  // Greece
  'el-GR',
  'en-GR',
  // Hungary
  'hu-HU',
  'en-HU',
  // Ireland
  'en-IE',
  // Latvia
  'lv-LV',
  'en-LV',
  // Lithuania
  'lt-LT',
  'en-LT',
  // Luxembourg
  'fr-LU',
  'de-LU',
  'en-LU',
  // Malta
  'en-MT',
  // Mexico
  'es-MX',
  'en-MX',
  // Norway
  'nb-NO',
  'en-NO',
  // Portugal
  'pt-PT',
  'en-PT',
  // Romania
  'ro-RO',
  'en-RO',
  // Slovakia
  'sk-SK',
  'en-SK',
  // Slovenia
  'sl-SI',
  'en-SI',
  // Spain
  'es-ES',
  'en-ES',
  // Sweden
  'sv-SE',
  'en-SE',
  // France
  'fr-FR',
  'en-FR',
  // Italy
  'it-IT',
  'en-IT',
  // UK
  'en-GB',
  // Germany
  'de-DE',
  'en-DE',
  // Netherlands
  'nl-NL',
  'en-NL',
  // Belgium
  'nl-BE',
  'fr-BE',
  'en-BE',
  // Switzerland
  'de-CH',
  'fr-CH',
  'it-CH',
  'en-CH',
  // Poland
  'pl-PL',
  'en-PL',
  // Brazil
  'pt-BR',
  'en-BR',
  // Chile
  'es-CL',
  'en-CL',
  // Colombia
  'es-CO',
  'en-CO',
  // Peru
  'es-PE',
  'en-PE',
  // US
  DEFAULT_LOCALE,
  'es-US',
]);

const NEXT_LOCALES = returnValidLocales(locales);

const LOCALES_WITH_SMALL_TRAFFIC = /** @type {const} */ ([
  'en-AT',
  'en-BG',
  'en-CY',
  'en-HR',
  'en-CZ',
  'en-DK',
  'en-EE',
  'en-FI',
  'en-GR',
  'en-HU',
  'en-LV',
  'en-LT',
  'en-LU',
  'en-NO',
  'en-PT',
  'en-RO',
  'en-SK',
  'en-SI',
  'en-ES',
  'en-SE',
  'en-FR',
  'en-IT',
  'en-DE',
  'en-NL',
  'en-BE',
  'en-CH',
  'en-PL',
  'en-BR',
  'en-CL',
  'en-CO',
  'en-PE',
]);

const WEBSITE_URL_BY_LOCALE = /** @type {const} */ ({
  'de-AT': 'sumup.at',
  'en-AT': 'sumup.at',
  'bg-BG': 'sumup.bg',
  'en-BG': 'sumup.bg',
  'el-CY': 'sumup.com.cy',
  'en-CY': 'sumup.com.cy',
  'hr-HR': 'sumup.hr',
  'en-HR': 'sumup.hr',
  'cs-CZ': 'sumup.cz',
  'en-CZ': 'sumup.cz',
  'da-DK': 'sumup.dk',
  'en-DK': 'sumup.dk',
  'et-EE': 'sumup.ee',
  'en-EE': 'sumup.ee',
  'fi-FI': 'sumup.fi',
  'en-FI': 'sumup.fi',
  'el-GR': 'sumup.gr',
  'en-GR': 'sumup.gr',
  'hu-HU': 'sumup.hu',
  'en-HU': 'sumup.hu',
  'en-IE': 'sumup.ie',
  'lv-LV': 'sumup.lv',
  'en-LV': 'sumup.lv',
  'lt-LT': 'sumup.lt',
  'en-LT': 'sumup.lt',
  'fr-LU': 'fr.sumup.lu',
  'de-LU': 'de.sumup.lu',
  'en-LU': 'fr.sumup.lu',
  'en-MT': 'sumup.com.mt',
  'nb-NO': 'sumup.no',
  'en-NO': 'sumup.no',
  'pt-PT': 'sumup.pt',
  'en-PT': 'sumup.pt',
  'ro-RO': 'sumup.ro',
  'en-RO': 'sumup.ro',
  'sk-SK': 'sumup.sk',
  'en-SK': 'sumup.sk',
  'sl-SI': 'sumup.si',
  'en-SI': 'sumup.si',
  'es-ES': 'sumup.es',
  'en-ES': 'sumup.es',
  'sv-SE': 'sumup.se',
  'en-SE': 'sumup.se',
  'fr-FR': 'sumup.fr',
  'en-FR': 'sumup.fr',
  'it-IT': 'sumup.it',
  'en-IT': 'sumup.it',
  'en-GB': 'sumup.co.uk',
  'de-DE': 'sumup.de',
  'en-DE': 'sumup.de',
  'nl-NL': 'sumup.nl',
  'en-NL': 'sumup.nl',
  'nl-BE': 'nl.sumup.be',
  'fr-BE': 'fr.sumup.be',
  'en-BE': 'nl.sumup.be',
  'de-CH': 'ch-de.sumup.com',
  'fr-CH': 'ch-fr.sumup.com',
  'it-CH': 'ch-it.sumup.com',
  'en-CH': 'ch-de.sumup.com',
  'pl-PL': 'sumup.pl',
  'en-PL': 'sumup.pl',
  'pt-BR': 'sumup.com.br',
  'en-BR': 'sumup.com.br',
  'es-CL': 'sumup.cl',
  'en-CL': 'sumup.cl',
  'es-CO': 'sumup.co',
  'en-CO': 'sumup.co',
  'es-PE': 'sumup.pe',
  'en-PE': 'sumup.pe',
  'en-US': 'sumup.com',
  'es-US': 'es.sumup.com',
  'es-AR': 'sumup.com.ar',
  'en-AU': 'sumup.com.au',
  'fr-CA': 'fr.sumup.ca',
  'en-CA': 'sumup.ca',
  'es-MX': 'sumup.mx',
  'en-MX': 'sumup.mx',
});

module.exports = {
  WEBSITE_URL_BY_LOCALE,
  NEXT_LOCALES,
  LOCALES_WITH_SMALL_TRAFFIC,
  DEFAULT_LOCALE,
  returnValidLocales,
};
