import {
  OptimizelyDecideOption,
  type OptimizelyUserContext,
} from '@optimizely/optimizely-sdk';

import type { Maybe } from 'types/util';

import logger from '../logger';
import { registerTrackingContext } from '../tracker/consumers/optimizely/handlers';

import { getOptimizelyClientForBrowser } from './browser-client';
import { getOptimizelyUserFromBrowser } from './browser-user';
import { getRunningFlagKeys } from './flag-config';

const DECIDE_OPTIONS_WITH_CONSENT = [OptimizelyDecideOption.ENABLED_FLAGS_ONLY];

// we don't want to send decision events when consent is not given
// so we disable the decision event
const DECIDE_OPTIONS_WITHOUT_CONSENT = [
  OptimizelyDecideOption.DISABLE_DECISION_EVENT,
  OptimizelyDecideOption.ENABLED_FLAGS_ONLY,
];

export const ensureBrowserSessionCreated = async (
  consentGiven: boolean,
  countryCode?: string,
): Promise<Maybe<OptimizelyUserContext>> => {
  const client = getOptimizelyClientForBrowser();

  await client.onReady();

  const decideOptions = consentGiven
    ? DECIDE_OPTIONS_WITH_CONSENT
    : DECIDE_OPTIONS_WITHOUT_CONSENT;

  const user = getOptimizelyUserFromBrowser(client, countryCode);
  const runningFlagKeys = getRunningFlagKeys();

  if (!user) {
    return null;
  }

  const decisions = user.decideForKeys(runningFlagKeys, decideOptions);

  registerTrackingContext({
    user,
    isEnabled: consentGiven,
  });

  Object.values(decisions)
    .map((d) => ({
      extra: {
        experimentKey: d.ruleKey,
        variationKey: d.variationKey,
        userId: d.userContext.getUserId(),
        userAttributes: d.userContext.getAttributes(),
        consentGiven,
      },
      msg: `Optimizely Flag Experimentation decision event status: ${
        consentGiven ? 'success' : 'blocked by user consent'
      }`,
    }))
    .forEach(({ extra, msg }) => logger.withContext({ extra }).info(msg));

  return user;
};
