import styled from '@emotion/styled';
import { Hamburger, Badge } from '@sumup-oss/circuit-ui';

export const Wrapper = styled('div')(
  ({ theme }) => `
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    background: var(--cui-bg-normal);
    z-index: ${theme.zIndex.popover};
    padding: ${theme.spacings.mega} ${theme.spacings.mega} ${theme.spacings.mega} 70px;
    border-bottom: ${theme.borderWidth.kilo} solid var(--cui-border-normal);
    `,
);

export const OptionsWrapper = styled('div')(
  ({ theme }) => `
    display: flex;
    flex-wrap: wrap;
    gap: ${theme.spacings.mega};
    `,
);
export const StyledHamburger = styled(Hamburger)(
  ({ theme }) => `
   position: absolute;
   top: ${theme.spacings.bit};
   left: ${theme.spacings.bit};
   z-index: ${theme.zIndex.tooltip};
   border: ${theme.borderWidth.kilo} solid var(--cui-border-normal);
    `,
);
export const StyledBadge = styled(Badge)(
  ({ theme }) => `
    position: absolute;
    top: ${theme.spacings.mega};
    left: ${theme.spacings.zetta};
    z-index: ${theme.zIndex.absolute};
    border: ${theme.borderWidth.kilo} solid var(--cui-border-normal);
  `,
);
