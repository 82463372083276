import type { PaymentMethod } from 'shared/infra/commerceLayer/types';
import type { Maybe } from 'types/util';

export enum PaymentActionTypes {
  SET_LOADING = '@payment/SET_LOADING',
  SET_ERROR = '@payment/SET_ERROR',

  ATTACH_PAYMENT_METHOD_REQUEST = '@payment/ATTACH_PAYMENT_METHOD_REQUEST',
  ATTACH_PAYMENT_METHOD_SUCCESS = '@payment/ATTACH_PAYMENT_METHOD_SUCCESS',
  ATTACH_PAYMENT_METHOD_FAILURE = '@payment/ATTACH_PAYMENT_METHOD_FAILURE',

  RESET_PAYMENT = '@payment/RESET_PAYMENT',
}

export type PaymentState = {
  readonly id: string;
  readonly reference: PaymentMethod;
  readonly loading: boolean;
  readonly error: Maybe<string>;
};

export interface SetPaymentLoadingAction {
  type: PaymentActionTypes.SET_LOADING;
  payload: { isLoading?: boolean };
}

export interface SetPaymentErrorAction {
  type: PaymentActionTypes.SET_ERROR;
  payload: { error: string };
}

export interface AttachPaymentMethod {
  type: PaymentActionTypes.ATTACH_PAYMENT_METHOD_REQUEST;
}

export interface AttachPaymentMethodSuccess {
  type: PaymentActionTypes.ATTACH_PAYMENT_METHOD_SUCCESS;
  payload: Pick<PaymentState, 'id' | 'reference'>;
}

export interface AttachPaymentMethodFailure {
  type: PaymentActionTypes.ATTACH_PAYMENT_METHOD_FAILURE;
  payload: { error: string };
}

export interface ResetPayment {
  type: PaymentActionTypes.RESET_PAYMENT;
}

export type PaymentActions =
  | SetPaymentLoadingAction
  | SetPaymentErrorAction
  | AttachPaymentMethod
  | AttachPaymentMethodSuccess
  | AttachPaymentMethodFailure
  | ResetPayment;
