import { useRouter } from 'next/router';

import type { Channel } from 'shared/constants/Channel';
import type { LOCALE_CODE } from 'shared/infra/contentful/contentful';
import {
  CLAYER_ORDER_ID,
  CLAYER_QUERY_ORDER_ID,
  ORDER_CATALOG,
  ORDER_LOCALE,
  PLACED_CLAYER_ORDER_ID,
  removeCookie,
  SALES_CHANNEL_AUTH_TOKEN,
} from 'shared/infra/cookies';
import { getChannel } from 'shared/utils/channel-link';
import isServer from 'shared/utils/is-server';

import logger from './logger';
import {
  retrieveGlobalCatalog,
  retrieveGlobalLocale,
  setGlobalCatalog,
  setGlobalLocale,
} from './OrderInformationService.globals';

export const removeLocalOrderInformation = (): void => {
  try {
    logger.debug(
      `removing order information ${localStorage.getItem(CLAYER_ORDER_ID)}`,
    );

    removeCookie(SALES_CHANNEL_AUTH_TOKEN);
    localStorage.removeItem(CLAYER_ORDER_ID);
    localStorage.removeItem(ORDER_LOCALE);
    localStorage.removeItem(ORDER_CATALOG);
  } catch (e) {
    if (!isServer) {
      logger.error(e, 'Unable to remove order and locale locally');
    }
  }
};

export const saveLocalOrderInformation = (orderId: string): void => {
  try {
    localStorage.setItem(CLAYER_ORDER_ID, orderId);
    localStorage.setItem(ORDER_LOCALE, retrieveGlobalLocale());
    localStorage.setItem(ORDER_CATALOG, retrieveGlobalCatalog());
  } catch (e) {
    if (!isServer) {
      logger.error(e, 'Unable to store order and locale locally');
    }
  }
};

export const retrieveLocalOrderInformation = (): {
  orderId: string;
  orderLocale: string;
  orderCatalog: Channel;
} => {
  let orderId: string;
  let orderLocale: string;
  let orderCatalog: Channel;

  try {
    orderId = getOrderId();
    orderLocale = localStorage.getItem(ORDER_LOCALE);
    orderCatalog = localStorage.getItem(ORDER_CATALOG) as Channel;
  } catch (e) {
    if (!isServer) {
      logger.error(e, 'Unable to retrieve order and locale locally');
    }
  }

  return { orderId, orderLocale, orderCatalog };
};

// We store the placed order id separately from the local order information.
// This is populated whenever the user places an order, and is used to
// render the thank you page independently of the local order information.
// On the thank you page we wipe the local order information to make sure
// that a placed order is not interacted with again (e.g. by navigating previous pages).
export const savePlacedOrderID = (orderId: string): void => {
  try {
    localStorage.setItem(PLACED_CLAYER_ORDER_ID, orderId);
  } catch (e) {
    if (!isServer) {
      logger.error(e, 'Unable to store placed order id locally');
    }
  }
};

export const retrievePlacedOrderID = (): string | null => {
  try {
    return localStorage.getItem(PLACED_CLAYER_ORDER_ID);
  } catch (e) {
    if (!isServer) {
      logger.error(e, 'Unable to retrieve placed order id locally');
    }
  }

  return null;
};

export const useLocalOrderInformation = (): void => {
  // TODO: REMOVE GLOBALS FROM THIS PROJECT!
  const { locale, pathname } = useRouter();
  const globalAppLocale = locale as LOCALE_CODE;
  // the contentful catalog used is dependent on (has the same value as) the channel
  const globalShopCatalog = getChannel();

  if (pathname?.includes('/auth/callback')) {
    return;
  }

  // save global reference to our locale
  setGlobalLocale(globalAppLocale);
  // save global reference to our channel
  setGlobalCatalog(globalShopCatalog);
};

export function getOrderId(): string | null {
  if (typeof window === 'undefined') {
    return null;
  }

  const orderId = localStorage.getItem(CLAYER_ORDER_ID);
  if (orderId) {
    return orderId;
  }

  const searchParams = new URLSearchParams(window.location.search);

  const searchOrderId = searchParams.get(CLAYER_QUERY_ORDER_ID);
  if (searchOrderId) {
    saveLocalOrderInformation(searchOrderId);
    return searchOrderId;
  }

  return null;
}
