import { setLogLevel } from '@optimizely/optimizely-sdk';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

import { getCountryCodeFromISOLocale } from 'shared/i18n/helpers';
import isServer from 'shared/utils/is-server';
import type { Maybe } from 'types/util';
import { useCookieConsent } from 'utils/scripts/cookieConsent/cookieConsent';

import logger from '../logger';

import { trackOptlyUserAssignment } from './assign-user';
import { ensureBrowserSessionCreated } from './browser-session';

export const useOptimizely = (): void => {
  const router = useRouter();
  const cookieConsent = useCookieConsent();

  const [userId, setUserId] = useState<Maybe<string>>(null);

  setLogLevel(logger.getLevel());

  useEffect(() => {
    if (!userId) {
      return;
    }

    trackOptlyUserAssignment(userId);
  }, [userId]);

  useEffect(() => {
    const fn = async (): Promise<void> => {
      if (router.isPreview || isServer) {
        return;
      }

      const countryCode = getCountryCodeFromISOLocale(router.locale);
      const consentGiven = cookieConsent.categories.includes('PERFORMANCE');

      const loadedUser = await ensureBrowserSessionCreated(
        consentGiven,
        countryCode,
      );

      if (loadedUser) {
        setUserId(loadedUser.getUserId());
      }
    };

    void fn();
  }, [router.locale, cookieConsent.categories, router.isPreview]);
};
