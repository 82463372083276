import { elb } from '@elbwalker/walker.js';
import createCache from '@emotion/cache';
import { CacheProvider, ThemeProvider } from '@emotion/react';
import { ModalProvider, ToastProvider } from '@sumup-oss/circuit-ui';
import '@sumup-oss/circuit-ui/styles.css';
import { light } from '@sumup-oss/design-tokens';
import '@sumup-oss/design-tokens/light.css';
import '@sumup-oss/design-tokens/fonts.css';
import type { NextPage } from 'next';
import type { AppProps as NextAppProps } from 'next/app';
import { useRouter } from 'next/router';
import {
  useEffect,
  type ComponentType,
  type FC,
  type PropsWithChildren,
  type ReactElement,
} from 'react';
import { useDispatch } from 'react-redux';

import { Layout } from 'shared/components/Layout';
import { NinetailedWrapper } from 'shared/components/NinetailedWrapper';
import { PreviewControls } from 'shared/components/Preview';
import { ShopHeader } from 'shared/containers/ShopHeader';
import { ContentProvider } from 'shared/context/Content';
import { FeatureFlagsProvider } from 'shared/context/FeatureFlags';
import { client as clClient } from 'shared/infra/commerceLayer/client';
import type { ProductGalleryItem } from 'shared/infra/commerceLayer/prices';
import logger from 'shared/services/logger';
import { useOptimizely } from 'shared/services/optimizely/useOptimizely';
import { useLocalOrderInformation } from 'shared/services/OrderInformationService';
import 'shared/services/tracker/consumers';
import { dispatchPageViewEvent } from 'shared/services/tracker/events';
import { setupWalker } from 'shared/services/walker/setup';
import { getTaggingData } from 'shared/services/walker/tagging';
import { AuthProvider } from 'shared/sso/AuthProvider';
import { withNextRedux } from 'shared/store';
import { provideCatalog } from 'shared/store/catalog/actions';
import isServer from 'shared/utils/is-server';
import { BrowserSupportScript } from 'utils/scripts/browser-support';
import {
  CookieConsentScript,
  shouldLoadCookieConsentScript,
} from 'utils/scripts/cookieConsent/cookieConsent';
import {
  GtmConsentScripts,
  GtmGtagScript,
  GtmScript,
  shouldLoadGtmScript,
} from 'utils/scripts/gtm/gtm';

if (!isServer) {
  void import('utils/tracer');
}

export interface AppProps {
  catalog?: {
    prices?: Record<string, ProductGalleryItem>;
  };
  content: Record<string, unknown>;
  prices?: Record<string, ProductGalleryItem>;
  marketCode?: string;
  isLogoClickable: boolean;
  isSystemPage?: true;
}

type NextPageWithLayout = NextPage<PropsWithChildren> & {
  getLayout?: (page: ReactElement) => ReactElement;
};

interface Props extends Pick<NextAppProps, 'Component'> {
  pageProps: PropsWithChildren<AppProps>;
  Component: NextPageWithLayout;
}

setupWalker();

const App: FC<Props> = ({ Component, pageProps }) => {
  const dispatch = useDispatch();
  const { pathname, isPreview, locale } = useRouter();
  // TODO migrate all props for pages to have catalog: { prices, products }
  const prices = pageProps.prices || pageProps.catalog?.prices;

  const shouldLoadGtm = shouldLoadGtmScript();
  const shouldLoadCookieConsent = shouldLoadCookieConsentScript();
  const isProductionMode = process.env.NODE_ENV === 'production';

  // populate catalog with prices
  useEffect(() => {
    if (prices) {
      dispatch(provideCatalog(prices));
    }
  }, [dispatch, prices]);

  // page view
  useEffect(() => {
    void dispatchPageViewEvent({});
    // track page views
    elb('walker run');
  }, [pathname]);

  useLocalOrderInformation();
  useOptimizely();

  useEffect(() => {
    if (pageProps.marketCode) {
      void clClient.init({
        marketCode: pageProps.marketCode,
        clientId: process.env.NEXT_PUBLIC_COMMERCE_LAYER_CLIENT_ID,
      });
    } else if (!pageProps.isSystemPage) {
      logger.error(new Error('Application missing market code!'));
    }
  }, [pageProps.isSystemPage, pageProps.marketCode]);

  if (Component.getLayout) {
    const { getLayout } = Component;
    return getLayout(<Component {...pageProps} />);
  }

  const isLogoClickable = pageProps?.isLogoClickable;

  return (
    <div
      {...getTaggingData({
        globals: {
          html_language: locale,
          app: 'shop',
        },
      })}
    >
      {isProductionMode && <BrowserSupportScript />}
      {shouldLoadCookieConsent && <CookieConsentScript />}
      {shouldLoadGtm && <GtmScript />}
      {shouldLoadGtm && <GtmGtagScript />}
      {shouldLoadGtm && shouldLoadCookieConsent && <GtmConsentScripts />}

      <ContentProvider content={{ ...pageProps.content }}>
        <FeatureFlagsProvider>
          <NinetailedWrapper>
            <ToastProvider position="top">
              <ModalProvider>
                {/* This root div is responsible to render the circuit-ui Modal.
                 * The modal uses this as a reference, thus making it necessary on the DOM.
                 */}
                <div id="root" />
                {isPreview && <PreviewControls />}
                <AuthProvider>
                  <Layout>
                    <ShopHeader isLogoClickable={isLogoClickable} />
                    {<Component {...pageProps} />}
                  </Layout>
                </AuthProvider>
              </ModalProvider>
            </ToastProvider>
          </NinetailedWrapper>
        </FeatureFlagsProvider>
      </ContentProvider>
    </div>
  );
};

const withTheme =
  (Component: ComponentType<unknown>): FC =>
  (props) => (
    <CacheProvider value={createCache({ key: 'storefront-web' })}>
      <ThemeProvider theme={light}>
        <Component {...props} />
      </ThemeProvider>
    </CacheProvider>
  );

export default withNextRedux(withTheme(App));
