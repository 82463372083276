import { elb } from '@elbwalker/walker.js';
import type { ListenerPayload } from '@optimizely/optimizely-sdk';

export type Decision = ListenerPayload & {
  type: string;
  decisionInfo?: {
    ruleKey?: string;
    variationKey?: string;
    flagKey?: string;
    enabled?: boolean;
    decisionEventDispatched?: boolean;
  };
};

export const handleDecisionEvent = ({ type, decisionInfo }: Decision): void => {
  if (type !== 'flag') {
    return;
  }

  const { ruleKey, variationKey, flagKey, enabled, decisionEventDispatched } =
    decisionInfo || {};

  // We can distinguish between a targeted rollout or an experiment based on decisionEventDispatched.
  // An experiment will have this value set to `true`.
  // A targeted delivery will return `false` as there is no dispatched event for rollouts.
  // https://docs.developers.optimizely.com/feature-experimentation/docs/set-up-google-analytics-4-ga4
  const flagRunsAsExperiment = decisionEventDispatched;
  const flagRunsAsFlagOnly = !flagRunsAsExperiment;

  window.dataLayer = window.dataLayer || [];

  if (flagRunsAsExperiment && ruleKey && variationKey) {
    window.dataLayer.push({
      event: 'optimizely',
      customParameters: {
        optimizelyExperimentKey: ruleKey,
        optimizelyVariationKey: variationKey,
      },
    });

    elb('optimizely-experiment activated', {
      optimizely_experiment_key: ruleKey,
      optimizely_variation_key: variationKey,
    });
  }

  if (flagRunsAsFlagOnly && flagKey) {
    elb('optimizely-feature decided', {
      optimizely_feature_enabled: enabled ?? false,
      optimizely_flag_key: flagKey,
    });
  }
};
