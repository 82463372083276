import { Col } from '@sumup-oss/circuit-ui/legacy';
import type { ReactNode, FC } from 'react';

/**
 * Since the Col component from Circuit UI isn't in TS yet,
 * it's failing the type-checking if you try to pass a object
 * with settings of responsive column into  "span" prop. The
 * type-checking says it should be a string.
 * To avoid having to wait for the fix to be available in Circuit,
 * we've followed this approach to override the Col's prop types.
 */
type Breakpoint =
  | 'default'
  | 'untilKilo'
  | 'kilo'
  | 'kiloToMega'
  | 'mega'
  | 'untilMega'
  | 'megaToGiga'
  | 'giga'
  | 'gigaToTera'
  | 'tera';
type ResponsiveSetup = { [key in Breakpoint]?: number } | string;
type ColumnProps = {
  span: ResponsiveSetup;
  skip?: ResponsiveSetup;
  children: ReactNode;
};
export const Column: FC<ColumnProps> = (props) => (
  <Col {...props} skip={props.skip ? props.skip : 0} />
);
