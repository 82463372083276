export const ACTIONS = {
  CLICKED: 'clicked',
  VIEW: 'view',
} as const;

export const TRIGGERS = {
  CLICK: 'click',
  VISIBLE: 'visible',
} as const;

export const ENTITIES = {
  BUTTON: 'button',
  PRODUCT: 'product',
} as const;

export const EVENTS = {
  ADD_TO_CART: 'add_to cart',
  BEGIN_CHECKOUT: 'begin checkout',
  GO_TO_CART: 'go_to cart',
  INPUT_ERROR: 'input error',
  LOGIN: 'login complete',
  OPTIMIZELY_EXPERIMENT_ACTIVATED: 'optimizely-experiment activated',
  PAYMENT_COMPLETE: 'payment complete',
  PAYMENT_ERROR: 'payment error',
  PAYMENT_SUCCESS: 'payment success',
  SHIPPING_COMPLETE: 'shipping complete',
  SKIP_SHOP: 'skip shop',
  SIGNUP: 'sign up',
  VOUCHER_APPLIED: 'voucher applied',
  REMOVE_FROM_CART: 'remove from cart',
  PAYMENT_ON_FILE_UPDATE_MODAL_OPENED: 'payment_on_file_update_modal opened',
  PAYMENT_ON_FILE_UPDATED: 'payment_on_file updated',
} as const;
